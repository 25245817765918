export const roundToHundred = (value) => {
  return value > 100 ? 100 : value
}

export const percentageCheck = (value) => {
  if (!isNaN(value) && value != null) {
    return roundToHundred(value) + '%'
  } else return ''
}

import dayjs from 'dayjs'

/**
 * convert date to Australian format
 * @returns YYYY-MM-DD
 */
export const convertDateToAuFormat = (date) => {
  return dayjs(date).format('YYYY-MM-DD')
}

/**
 * Obtain last day of current year ,
 * This is mainly for datepicker to not go over next year
 *
 */

export const getLastDayOfYear = () => {
  let lastDayOfTheYear

  lastDayOfTheYear = new Date(new Date().getFullYear(), 11, 31)

  return lastDayOfTheYear
}

/**
 * Join array of words with commas with 'and' at the end to form a sentence.
 * @returns example of " one, two and three "
 */
export const formatLongConjunction = (ary) => {
  const formatter = new Intl.ListFormat('en', {
    style: 'long',
    type: 'conjunction',
  })
  return formatter.format(ary).toLowerCase()
}
