export function combineFormula(a, b) {
  /**
   * Used to calculate the values using the following formula
   * Do not round off any values here!
   */
  let result
  // Convert percentage values to decimals
  a /= 100
  b /= 100
  // Combine values formula
  result = a + b * (1 - a)
  // Convert result back to percentage
  result *= 100
  // Return result
  return result
}

export const getCombinedValue = (array) => {
  /**
   * Returns the combined values after combining via the formula
   */

  // Initialize values
  let A = null
  // Loop through each input
  for (let input in array) {
    // Get value of input
    // Make sure value is rounded
    const value = Math.round(array[input])
    // Check if value is null
    if (value === null) {
      // Prevent calculation if there's an empty input
      return null
    }
    // Initialize for first value
    if (A === null) {
      A = value
      // Continue to second loop
      continue
    }
    //  Combine values via formula

    A = combineFormula(A, value)
  }
  // Return rounded value

  return Math.round(A)
}

export const convertUeiToWpi = (value) => {
  return Math.round((Math.round(value) * 60) / 100)
}

// Body Parts Calculations

/**
 * HandImpairment to Upper Extremity Impairment  needs to multiply by 0.9 and round off
 * @param {*} Number accepts HI value
 */
export const convertHiToUei = (hand) => {
  return Math.round(hand * 0.9)
}

/**
 * Thumb to HandImpairment needs to multiply by 0.4 and round off
 * @param {*} Number accepts total sum of thumb impairment value
 */
export const convertThumbToHi = (thumb) => {
  return Math.round(thumb * 0.4)
}

/**
 * Index & Middle to HandImpairment needs to multiply by 0.2 and round off
 * @param {*} Number accepts finger combined digital total value
 */
export const convertFingerIndexMiddleToHi = (fingerCD) => {
  return Math.round(fingerCD * 0.2)
}

/**
 * Ring & Pinky to HandImpairment needs to multiply by 0.2 and round off
 * @param {*} Number accepts finger combined digital total value
 */
export const convertFingerRingPinkyToHi = (fingerCD) => {
  return Math.round(fingerCD * 0.1)
}
