export const STORE_IMPAIRMENTS = (state, payload) => {
  let getExistingIndex = state.assessmentValues.findIndex(
    (x) => x.bodyPart == payload.bodyPart
  )

  getExistingIndex > -1
    ? (state.assessmentValues[getExistingIndex] = payload)
    : state.assessmentValues.push(payload)
}

export const STORE_ROW_SELECTED_ASSESSMENT = (state, payload) => {
  state.rowSelectedAssessment = payload
}

export const STORE_ASSESSMENTDATA = (state, payload) => {
  state.assessmentData = payload
}

export const STORE_SPINE_DATA = (state, payload) => {
  //replace existing with new or push new to array
  let foundObj = state.spineData.findIndex(
    (x) => x.spine_name === payload.spine_name
  )

  if (foundObj === -1) {
    state.spineData.push(payload)
  } else {
    state.spineData[foundObj] = payload
  }
}

export const STORE_SELECTED_SPINE = (state, payload) => {
  state.selectedSpine = payload
}

export const STORE_BODYPARTS = (state, payload) => {
  state.selectedBodyParts = state.selectedBodyParts.concat(payload)
}

export const STORE_ASSESSMENTID = (state, payload) => {
  state.assessmentID = payload
}

export const STORE_PATIENTID = (state, payload) => {
  state.patientID = payload
}

export const STORE_MEDICALRECORDID = (state, payload) => {
  state.medicalRecordID = payload
}

export const STORE_REFID = (state, payload) => {
  state.referralID = payload
}

export const SET_TOKEN = (state, { access }) => {
  state.accessToken = access

  localStorage.setItem('token', access)
}

//tpd
export const STORE_PATIENTDOCS = (state, payload) => {
  state.patientDocs = payload
}

export const STORE_TPDREFERRAL = (state, payload) => {
  state.TPDReferral = payload
}

//RTWSA

import {
  convertHiToUei,
  convertThumbToHi,
  convertFingerIndexMiddleToHi,
  convertFingerRingPinkyToHi,
  convertUeiToWpi,
  getCombinedValue,
} from '@/utils/calculations.js'

import { roundToHundred } from '@/utils/generics.js'

/**
 * Calculate extremity impairment values
 * accepts response data from api call and processes it
 * @param {string} state - vuex state name.
 * @param {array} payload - response.data from api.
 *
 * @returns {obj} return array of assessed body parts, hand impairments, uei impairments and wpi impairments
 */

export const CALCULATE_IMPAIRMENTS = (state, payload) => {
  let filteredAry = []

  // Final output
  let output = {
    body_parts: [],
    hi: { right: [], left: [], total_left: 0, total_right: 0 },

    // The reason UEI uses array is because it needs to go through combine value formula for WPI
    // Elements inside array is obj from body_parts filter by direction
    uei: { right: [], left: [], total_left: 0, total_right: 0 },

    wpi: { right: 0, left: 0, spine: 0, total: 0 },
  }

  // To add impairment value if excision is present
  function calculateUEI(ary) {
    if (ary) {
      let result = null

      ary.forEach((item) => {
        if (item.field_name === 'ac joint excision' && item.value === 'yes') {
          item.impairment_value = 5
        }
        result += item.impairment_value ?? 0
      })

      return roundToHundred(Math.round(result))
    } else return null
  }

  // Finger's total digital impairment needs to go through combined value formula
  function calculateFingerDI(ary) {
    let obj = { dip_total: 0, pip_total: 0, mp_total: 0 }
    ary?.forEach((e) => {
      if (e.field_name.includes('dip')) {
        obj.dip_total += e.impairment_value
      } else if (e.field_name.includes('pip')) {
        obj.pip_total += e.impairment_value
      } else {
        return (obj.mp_total += e.impairment_value)
      }
    })

    // Finalize array for combine value calculations
    let combinedValAry = Object.values(obj)

    let output = getCombinedValue(combinedValAry)

    return roundToHundred(output)
  }

  // Generate display title based on selected sides,
  // Re-process data for frontend
  payload.reduce((prev, current) => {
    let obj = {
      title: current.display_name.toLowerCase(),
      direction: !current.direction
        ? []
        : current.direction.includes('L')
        ? ['left']
        : ['right'],
      ama: current.citation_ama5,
      guideline: current.citation_guideline,
    }

    if (prev && current && prev.name === current.name) {
      // Because the filtered array will always have the last obj as prev , so if we found same name, remove , edit and push again
      filteredAry.pop()

      if (prev.is_selected && current.is_selected) {
        obj.direction = ['right', 'left']
      } else {
        obj.direction = current.is_selected
          ? [current.direction === 'R' ? 'right' : 'left']
          : [prev.direction === 'R' ? 'right' : 'left']
      }

      obj[
        `${
          !prev.direction
            ? 'fields'
            : prev.direction.includes('L')
            ? 'left'
            : 'right'
        }`
      ] = prev.fields
      obj[
        `${
          !current.direction
            ? 'fields'
            : current.direction.includes('L')
            ? ['left']
            : ['right']
        }`
      ] = current.fields

      obj.specific_diagnosis = {}

      obj.specific_diagnosis[
        `${
          !prev.direction
            ? 'diagnosis'
            : prev.direction.includes('L')
            ? 'left'
            : 'right'
        }`
      ] = prev.specific_diagnosis
      obj.specific_diagnosis[
        `${
          !current.direction
            ? 'diagnosis'
            : current.direction.includes('L')
            ? ['left']
            : ['right']
        }`
      ] = current.specific_diagnosis

      // For uei and wpi calculation to be done later
      obj[
        `${
          !prev.direction
            ? ''
            : prev.direction.includes('L')
            ? 'left_'
            : 'right_'
        }calculation`
      ] = { uei: 0, wpi: 0 }

      obj[
        `${
          !current.direction
            ? ''
            : current.direction.includes('L')
            ? 'left_'
            : 'right_'
        }calculation`
      ] = { uei: 0, wpi: 0 }

      filteredAry.push(obj)

      // Setting accumulated value to null when found repeated

      return null
    } else {
      obj[
        `${
          !current.direction
            ? 'fields'
            : current.direction.includes('L')
            ? ['left']
            : ['right']
        }`
      ] = current.fields

      obj[
        `${
          !current.direction
            ? ''
            : current.direction.includes('L')
            ? 'left_'
            : 'right_'
        }calculation`
      ] = { uei: 0, wpi: 0 }

      obj.specific_diagnosis = {}

      obj.specific_diagnosis[
        `${
          !current.direction
            ? 'diagnosis'
            : current.direction.includes('L')
            ? 'left'
            : 'right'
        }`
      ] = current.specific_diagnosis

      // SPECIAL CASE FOR SPINE TEMPORARY

      if (current.display_name.toLowerCase().includes('spine')) {
        obj[`spine_data`] = current.spine_data
        filteredAry.push(obj)
      } else filteredAry.push(obj)

      // If prev and current is not same, push the prev obj and return current for next comparison check cycle
      return current
    }
  }, null)

  filteredAry.forEach((item) => {
    // Headers must have or start with
    item.examinationHeader = ['motion']
    item.impairmentHeader = ['']

    // Handle Spine data
    // Temporary only for spines, spine has no left and right
    if (item.title.includes('spine')) {
      output.wpi.spine = item['calculation'].wpi

      // Header for spine only
      // Spine doesn't use rateable impairment table component
      item.examinationHeader = ['motion', '']

      item['calculation'].uei = calculateUEI(item['field'])

      item['calculation'].wpi = convertUeiToWpi(item['calculation'].uei)
    }

    // Check if theres value inside the opposite ( cannot have null )

    if (item.direction.length && item.direction.length === 1) {
      let opposite = item.direction[0] === 'left' ? 'right' : 'left'

      let oppositeAllNulls = item[opposite].every(
        (x) => x.impairment_value === null || x.value === ''
      )

      // If opposite values are all empty/unassigned
      // Remove unused object properties

      if (oppositeAllNulls) {
        delete item[opposite]
        delete item[`${opposite}_calculation`]
      }
    }

    // Count individual UEI and WPI
    // Non Spine

    // Remember always do right side first
    let direction = ['right', 'left']

    direction.forEach((d) => {
      // Calculate sides that are present
      // If not needed, the above logic removes unused direction

      if (item?.[d]) {
        // Map table headers
        item.examinationHeader.push(d)
        item.impairmentHeader.push(d, `${d} upper extremity impairment (%) `)

        // Remove underscore in field name for table's display title

        item[d].forEach((x) => {
          return (x.field_name = x.field_name.replace(/_/g, ' '))
        })

        // Fingers have special ways to calculate HI, UEI, WPI
        if (item.title.includes('thumb')) {
          // To calculate combined digital impairment for thumb,
          // It uses the same method as normal total uei equation
          item[`${d}_calculation`].di = calculateUEI(item[d])

          // Thumb to hand impairment
          item[`${d}_calculation`].hi = convertThumbToHi(
            item[`${d}_calculation`].di
          )

          // Add to total HI
          output.hi[d].push({
            body_part: item.title,
            value: item[`${d}_calculation`].hi,
            ama: item.ama,
            guideline: item.guideline,
          })

          // Calculate thumb's uei
          item[`${d}_calculation`].uei = convertHiToUei(
            item[`${d}_calculation`].hi
          )

          item[`${d}_calculation`].wpi = convertUeiToWpi(
            item[`${d}_calculation`].uei
          )
        } else if (item.title.includes('finger')) {
          // Calculate combined digital impairment for finger only
          item[`${d}_calculation`].di = calculateFingerDI(item[d])

          // Check what position of fingers, as 2-3 and 4-5 has different equation

          // This is for index & middle

          if (item.title.includes('index') || item.title.includes('middle')) {
            item[`${d}_calculation`].hi = convertFingerIndexMiddleToHi(
              item[`${d}_calculation`].di
            )
          } else {
            item[`${d}_calculation`].hi = convertFingerRingPinkyToHi(
              item[`${d}_calculation`].di
            )
          }

          // Add to total HI
          output.hi[d].push({
            body_part: item.title,
            value: item[`${d}_calculation`].hi,
            ama: item.ama,
            guideline: item.guideline,
          })

          // Calculate individual finger's UEI
          item[`${d}_calculation`].uei = convertHiToUei(
            item[`${d}_calculation`].hi
          )

          // Calculate individual finger's WPI
          item[`${d}_calculation`].wpi = convertUeiToWpi(
            item[`${d}_calculation`].uei
          )
        }
        // Not fingers
        else {
          // Process individual UEI
          if (item[d]) {
            item[`${d}_calculation`].uei = calculateUEI(item[d])
          }

          // Only display final UEI and WPI on selected body part
          // Dont include opposite direction's value

          if (item.direction.includes(d)) {
            // Not fingers, so directly use UEI
            output.uei[d].push({
              body_part: item.title,
              value: item[`${d}_calculation`].uei,
              ama: item.ama,
              guideline: item.guideline,
            })

            // Individual WPI
            item[`${d}_calculation`].wpi = convertUeiToWpi(
              item[`${d}_calculation`].uei
            )
          }
        }
      }
    })

    // Final push for impairment/assessment table to render data
    output.body_parts.push(item)
  })

  // Calculate total UEI and WPI
  //

  // Total up hand impairments first

  output.hi.total_left = output.hi.left
    .map((e) => e.value)
    .reduce((initialSum, a) => initialSum + a, 0)

  output.hi.total_right = output.hi.right
    .map((e) => e.value)
    .reduce((initialSum, a) => initialSum + a, 0)

  // Add hand UEI to array with if there is
  // Display individual finger if only 1 finger is selected

  if (output.hi.left.length) {
    if (output.hi.left.length === 1) {
      // If only 1 finger, display finger name
      output.uei.left.push({
        ...output.hi.left[0],
        value: convertHiToUei(output.hi.total_left),
      })
    } else
      output.uei.left.push({
        body_part: 'hand',
        value: convertHiToUei(output.hi.total_left),
      })
  }

  if (output.hi.right.length) {
    if (output.hi.right.length === 1) {
      // If only 1 finger, display finger name
      output.uei.right.push({
        ...output.hi.right[0],
        value: convertHiToUei(output.hi.total_right),
      })
    } else
      output.uei.right.push({
        body_part: 'hand',
        value: convertHiToUei(output.hi.total_right),
      })
  }

  // Total up UEI

  if (output.uei.left.length) {
    output.uei.total_left = getCombinedValue(
      output.uei.left.map((e) => e.value)
    )
  }

  if (output.uei.right.length) {
    output.uei.total_right = getCombinedValue(
      output.uei.right.map((e) => e.value)
    )
  }

  // WPI
  output.wpi.left = convertUeiToWpi(output.uei.total_left)

  output.wpi.right = convertUeiToWpi(output.uei.total_right)

  // Calculate Total WPI of all assessed parts
  output.wpi.total = getCombinedValue(Object.values(output.wpi))

  return (state.extremityCalculations = output)
}
