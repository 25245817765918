export default {
  count: 54321,
  name: 'test',

  accessToken: null,
  refreshToken: null,
  APIDate: '',
  assessmentValues: [],
  assessmentID: null,
  patientID: null,
  referralID: null,
  medicalRecordID: null,

  assessmentData: null,

  //temporary store spine data in frontend
  spineData: [],
  selectedSpine: null,

  selectedBodyParts: [],
  //this is taken from assessment list table row selection
  rowSelectedAssessment: null,

  //uploaded tpd med documentss
  patientDoc: [],

  //referral TPD ID
  TPDReferral: null,

  //profile TPD
  profile: null,

  //assessment TPD
  TPDAssessment: null,

  //templates TPD
  TPDTemplates: null,

  //hardcoded IDs for document type
  document_types: [
    {
      title: 'GP Notes',
      id: 1,
      api_title: 'gp_notes',
    },

    {
      title: 'Specialist Documents',
      id: 7,
      api_title: 'specialist_docs',
    },
    {
      title: 'Surgical Note',
      id: 8,
      api_title: 'surgical_notes',
    },
    {
      title: 'Investigation reports',
      id: 3,
      api_title: 'investigation_reports',
    },
    {
      title: 'Hospital Record',
      id: 2,
      api_title: 'hospital_records',
    },
    {
      title: 'Medicolegal Documents',
      id: 5,
      api_title: 'medicolegal_docs',
    },
  ],

  //State ID for referral makings
  stateID: null,

  // Service requirement, takes in name of service in string
  serviceRequirement: null,

  // Populate with Total HI , UEI , WPI
  // body_part property will be populated with selected impaired body part and individual UEI WPI data.
  extremityCalculations: {
    body_parts: [],
    hi: { right: 0, left: 0 },
    uei: { right: [], left: [] },
    wpi: { right: 0, left: 0, spine: 0, total: 0 },
  },
}
