/**
 * Param builder for api call that has search or filter functionality
 * Produce an object that only has property with value
 * */
export const objWithValues = (obj) => {
  if (Object.keys(obj).length != 0) {
    return Object.fromEntries(
      Object.entries(obj).filter(([value]) => !isNaN(value) || value)
    )
  } else return ''
}
