<template>
  <div id="app">
    <router-view />
  </div>
  <ToastWrapper id="toast-wrapper" v-model:toasts="toasts" />
</template>

<script>
import ToastWrapper from '@/components/Toast/ToastWrapper.vue'
export default {
  name: 'App',
  components: { ToastWrapper },
  data() {
    return {
      toasts: [],
    }
  },
}
</script>

<style lang="scss">
#app {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
