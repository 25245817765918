<template>
  <div
    :id="id"
    class="toast hide"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    :data-bs-autohide="autohide"
    :data-bs-delay="delay"
  >
    <div class="toast-header" :class="getClass">
      <strong class="me-auto text-white">{{ getTitle }}</strong>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></button>
    </div>
    <div class="toast-body">
      <slot>{{ getMessage }}</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToastBase',
  props: {
    /**
     * The button types
     * @values danger, success, warning
     */
    type: {
      type: String,
      required: true,
    },
    delay: {
      type: Number,
      default: 2000,
    },
    autohide: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: 'base-toast',
    },
  },
  computed: {
    getID() {
      return `${this.id}-${new Date().getTime()}`
    },
    getClass() {
      return `bg-${this.type}`
    },
    getTitle() {
      switch (this.type) {
        case 'danger':
          return 'Error'
        case 'success':
          return 'Success'
        case 'warning':
          return 'Warning'
        default:
          return ''
      }
    },
    getMessage() {
      switch (this.type) {
        case 'danger':
          return 'Sorry, system error detected.'
        case 'success':
          return 'Saved successfully.'
        case 'warning':
          return 'Please check if your input was done correctly.'
        default:
          return ''
      }
    },
  },
}
</script>
