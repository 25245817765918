<template>
  <div
    v-if="toasts?.length > 0"
    style="z-index: 1060"
    class="toast-container position-fixed bottom-0 end-0 p-3 m-4"
  >
    <div v-for="(toast, idx) in toasts" :key="toast.content">
      <ToastBase :id="toast.status + idx" :type="toast.status">
        {{ toast.content }}
      </ToastBase>
    </div>
  </div>
</template>

<script>
import { Toast } from 'bootstrap'
import ToastBase from '@/components/Toast/ToastBase.vue'

export default {
  name: 'ToastWrapper',
  components: { ToastBase },
  props: {
    /**
     * Array of toasts to be displayed
     *
     * @type {object}
     * @property {string} status
     *  @values danger, success, warning
     */
    toasts: {
      type: Array,
      default: null,
    },
  },
  emits: ['update:toasts'],
  data() {
    return {
      toastArr: [],
      toastTimeout: null,
      clearTimeout: null,
    }
  },
  updated() {
    if (this.toasts.every((e) => typeof e === 'object')) {
      // Clear timeouts when components get updated
      if (this.toastTimeout) clearTimeout(this.toastTimeout)
      if (this.clearTimeout) clearTimeout(this.clearTimeout)
      // Loop each toast
      this.toasts.forEach((t, idx) => {
        this.toastTimeout = setTimeout(() => {
          // Declare element as toast
          const toast = new Toast(`#${t.status}${idx}`)
          // Display toast
          toast.show()
          // Set a timeout that'll clear the toasts to prevent
          // endless tacking
          this.clearTimeout = setTimeout(() => {
            this.$emit('update:toasts', [])
          }, 2500)
          return
        }, idx * 0)
      })
    }
  },
  methods: {
    getToasts() {
      this.toastArr = this.toasts
      return this.toastArr
    },
  },
}
</script>
