import { createApp } from 'vue'
// import { store } from './store';

import App from './App.vue'
import router from './router'
import store from './store/index'

import 'bootstrap'

store.dispatch('attemptLogin', localStorage.getItem('token')).then(() => {
  createApp(App).use(router).use(store).mount('#app')
})
