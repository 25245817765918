import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    // Login
    path: '/login',
    component: () => import('@/components/Layout/LoginLayout.vue'),
    children: [
      {
        path: '',
        name: 'login',
        component: () => import('@/views/Login.vue'),
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/ForgotPassword.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/components/Layout/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'calculator',
        component: () => import('@/views/Calculators.vue'),
      },
      {
        path: '',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: 'body-part-selection',
        name: 'body-part-selection',
        component: () => import('@/views/BodyPartSelection.vue'),
      },
      {
        path: 'state-selection',
        name: 'state-selection',
        component: () => import('@/views/StateSelection.vue'),
      },

      {
        path: 'service-requirement-selection',
        name: 'service-requirement-selection',
        component: () => import('@/views/ServiceRequirementView.vue'),
      },

      {
        path: 'assessment-building/:id/',
        name: 'assessment-building',
        component: () => import('@/views/AssessmentTemplate.vue'),

        children: [
          {
            path: 'upper-body-parts',
            name: 'upper-body-parts',
            component: () => import('@/views/BodyPart/UpperBodyParts.vue'),
            props: true,
          },
          {
            path: 'fingers',
            name: 'fingers',
            component: () => import('@/views/BodyPart/Fingers.vue'),
          },
          {
            path: 'lumbar-spine',
            name: 'lumbar-spine',
            component: () => import('@/views/BodyPart/Spines/LumbarSpine.vue'),
          },
          {
            path: 'thoracic-spine',
            name: 'thoracic-spine',
            component: () =>
              import('@/views/BodyPart/Spines/ThoracicSpine.vue'),
          },
          {
            path: 'cervical-spine',
            name: 'cervical-spine',
            component: () =>
              import('@/views/BodyPart/Spines/CervicalSpine.vue'),
          },
        ],
      },

      // Impairex
      // Lawyer only
      //
      {
        path: 'referral/:id/',
        name: 'lawyer-referral-layout',
        component: () => import('@/layouts/ImpairEx/LawyerReferralLayout.vue'),

        children: [
          {
            path: 'referral-form',
            name: 'lawyer-referral-creation',
            component: () => import('@/views/ImpairEx/LawyerReferralForm.vue'),
          },
          {
            path: 'referral-document-uploads',
            name: 'lawyer-referral-document-uploads',
            component: () =>
              import('@/views/ImpairEx/LawyerReferralDocumentUploads.vue'),
          },
          {
            path: 'referral-created',
            name: 'lawyer-referral-created',
            component: () =>
              import('@/views/ImpairEx/LawyerReferralCreated.vue'),
          },
        ],
      },

      {
        path: 'profile',
        name: 'profile',
        component: () => import('@/views/Profile.vue'),
      },
      {
        path: 'new-assessment',
        name: 'new-assessment',
        component: () => import('@/views/NewAssessment.vue'),
      },
      {
        path: 'assessment-list',
        name: 'assessment-list',
        component: () => import('@/views/AssessmentList.vue'),
      },

      //TPD
      //special children
      {
        path: 'referral/:id/',
        name: 'referral',
        component: () => import('@/views/TPD/ReferralBuilding.vue'),

        children: [
          {
            path: 'tpd-lawyer',
            name: 'tpd-lawyer',
            component: () => import('@/views/TPD/TpdLawyerForm.vue'),
          },
          {
            path: 'referral-information-uploads',
            name: 'referral-information-uploads',
            component: () => import('@/views/TPD/ReferralInformation.vue'),
          },
          {
            path: 'referral-created',
            name: 'referral-created',
            component: () => import('@/views/TPD/ReferralCreated.vue'),
          },
        ],
      },
      {
        path: 'tpd/:id/',
        name: 'non-lawyer-template',
        component: () => import('@/views/TPD/NonLawyerTemplate.vue'),

        children: [
          {
            path: 'tpd-admin',
            name: 'tpd-admin',
            component: () => import('@/views/TPD/TpdAdminPage.vue'),
          },
          {
            path: 'tpd-nurse',
            name: 'tpd-nurse',
            component: () => import('@/views/TPD/TpdNurseForm.vue'),
          },
          {
            path: 'tpd-doctor',
            name: 'tpd-doctor',
            component: () => import('@/views/TPD/TpdDoctorReport.vue'),
          },
          {
            path: 'tpd-specialist',
            name: 'tpd-specialist',
            component: () => import('@/views/TPD/TpdSpecialistReport.vue'),
          },
          {
            path: 'tpd-completed',
            name: 'tpd-completed',
            component: () => import('@/views/TPD/TpdCompletedPage.vue'),
          },
        ],
      },

      {
        path: 'tpd-list',
        name: 'tpd-list',
        component: () => import('@/views/TPD/TpdList.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  // Add login check
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!localStorage.getItem('token')) {
      this.$router.push({ name: 'login', query: { redirect: '/login' } })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
