/* eslint-disable no-unused-vars */
import { setAuthTokens, clearAuthTokens } from 'axios-jwt'

import { api, apiAuth } from '../axios-api'

import { objWithValues } from '@/utils/paramBuilder.js'

export const login = async (_, params) => {
  const response = await apiAuth.post('login/', params)

  // save tokens to storage
  setAuthTokens({
    accessToken: response.data.access_token,
    refreshToken: response.data.refresh_token,
  })

  return response
}

// 5. Logging out
export const logout = () => clearAuthTokens()

export const attemptLogin = ({ commit, state }, token) => {
  if (token) {
    commit('SET_TOKEN', { access: token })
  }

  if (!state.accessToken) {
    return
  }
}

export const postNewAssessment = async (_, payload) => {
  return await api.post(`assessment/`, payload)
}

export const getAssessment = async (_, { id }) => {
  return await api.get(`assessment/${id}/`)
}

export const patchAssessment = async (_, { id, payload }) => {
  return await api.patch(`assessment/${id}/`, payload)
}

// for pairing body part name to ID , api call relies on ID
export const getBodyPartsList = async (_) => {
  return await api.get(`body-part/`)
}

// Update a new assessment body parts with the provided data
// and any body parts not provided but was previously selected will be deleted

export const patchAssessmentBodyParts = async (_, { id, payload }) => {
  return await api.patch(`assessment/${id}/body-part/`, payload)
}

// for degrees, and movements
export const getBodyPartDetails = async (_, { id }) => {
  return await api.get(`body-part/${id}/`)
}

export const putNewBodyPartToAssessment = async (_, { id, payload }) => {
  return await api.put(`assessment/${id}/body-part/`, payload)
}

// Get existing patient basic info

export const getPatientList = async (_, { name, page }) => {
  let output = objWithValues({ ...name, ...page })
  return await api.get(`/patient/`, {
    params: output,
  })
}

export const getPatientInfo = async (_, payload) => {
  return await api.get(`/patient/${payload.id}/`)
}

export const postNewPatient = async (_, payload) => {
  return await api.post(`/patient/`, payload)
}

//partial update patient basic info
export const patchPatientInfo = async (_, payload) => {
  return await api.put(`/patient/${payload.id}/`, payload)
}

//referrals
//example = ?email=2&name=2&page=1
export const getReferralList = async (_, { name, page = 1 }) => {
  let output = objWithValues({ ...name, ...page })
  return await api.get(`/referrer/`, {
    params: output,
  })
}

export const getReferral = async (_, { id }) => {
  return await api.get(`/referrer/${id}/`)
}

export const postNewReferral = async (_, payload) => {
  return await api.post(`/referrer/`, payload)
}

//TPD

export const getTPDProfile = async () => {
  return await api.get(`/tpd/profile/`)
}

export const updateTPDProfile = async (_, { id, payload }) => {
  return await api.put(`/tpd/profile/${id}/`, payload)
}

export const getTPDPatientList = async (_, { name, page = 1 }) => {
  let output = objWithValues({ ...name, ...page })
  return await api.get(`/tpd/patient/`, {
    params: output,
  })
}

export const putTPDPatientDetails = async (_, { id, payload }) => {
  return await api.put(`/tpd/assessment/${id}/patient-detail/`, payload)
}

export const postTPDNewPatient = async (_, payload) => {
  return await api.post(`/tpd/patient/`, payload)
}

export const getTPDNurseList = async (_, { name, page }) => {
  let output = objWithValues({ ...name, ...page })
  return api.get(`/tpd/nurse/`, {
    params: output,
  })
}

export const getTPDSpecialistList = async (_, { name, page }) => {
  let output = objWithValues({ ...name, ...page })
  return await api.get(`/tpd/specialist/`, {
    params: output,
  })
}

export const getTPDDoctorList = async (_, { name, page }) => {
  let output = objWithValues({ ...name, ...page })
  return await api.get(`/tpd/doctor/`, {
    params: output,
  })
}

export const patchTPDDocConsult = async (_, { id, payload }) => {
  return await api.patch(`/tpd/assessment/${id}/doctor-consultation/`, payload)
}

export const patchTPDSpecialistConsult = async (_, { id, payload }) => {
  return await api.patch(
    `/tpd/assessment/${id}/specialist-consultation/`,
    payload
  )
}

export const delTPDReferralDoc = async (_, { id }) => {
  return await api.delete(`/tpd/referral-document/${id}/`)
}

export const getTPDReferral = async (_, { id }) => {
  return await api.get(`/tpd/referral/${id}/`)
}

export const getTPDReferralList = async (_) => {
  return await api.get(`/tpd/referral/`)
}

export const delTPDReferral = async (_, { id }) => {
  return await api.delete(`/tpd/referral/${id}/`)
}

export const postTPDNewReferral = async (_, { payload }) => {
  return await api.post(`/tpd/referral/`, payload, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
}

export const patchTPDReferral = async (_, { id, payload }) => {
  return await api.patch(`/tpd/referral/${id}/`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getTPDAdmin = async (_, { id }) => {
  return await api.get(`/tpd/admin/${id}/`)
}

export const patchTPDAdmin = async (_, { id, payload }) => {
  return await api.patch(`/tpd/admin/${id}/`, payload)
}

export const delTPDNewReport = async (_, { id }) => {
  return await api.delete(`/tpd/tpd/${id}/`)
}

export const getTPDList = async (_, payload) => {
  return await api.get(`/tpd/assessment/`, { params: payload })
}

export const getTPDAssessment = async (_, { id }) => {
  return await api.get(`/tpd/assessment/${id}/`)
}

export const patchTPDAssessment = async (_, { id, payload }) => {
  return await api.patch(`/tpd/assessment/${id}/`, payload)
}

export const putInvoice = async (_, { id, payload }) => {
  return await api.put(`/tpd/assessment/${id}/invoice/`, payload)
}

export const getTPDTemplates = async (_) => {
  return await api.get(`/tpd/referral/template/`)
}

//mark as done
export const markDoc = async (_, { id }) => {
  return await api.get(`/tpd/referral-document/${id}/mark-downloaded/`)
}
//Retrieve states
export const getStates = async (_) => {
  return await api.get(`/state/`)
}
