import { applyAuthTokenInterceptor, clearAuthTokens } from 'axios-jwt'
import axios from 'axios'
import router from './router'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

const BASE_URL = process.env.VUE_APP_API_URL

// 1. Create an axios instance that you wish to apply the interceptor to
export const api = axios.create({
  baseURL: `${BASE_URL}/api`,
})
export const apiAuth = axios.create({
  baseURL: `${BASE_URL}/api-auth`,
})

// 2. Define token refresh function.
const requestRefresh = (refresh) => {
  return axios
    .post(`${BASE_URL}/api-auth/token/refresh/`, { refresh })
    .then((response) => response.data.access)
    .catch((error) => {
      clearAuthTokens()
      console.log(error, 'token refresh error occured')
      router.push({ name: 'login' })
    })
}

// 3. Apply interceptor
applyAuthTokenInterceptor(api, { requestRefresh })
